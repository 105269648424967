import React from "react";
import "./NotFound.scss";

export function NotFound() {
    return (
        <div className="not-found">
            <h1>Pagina niet gevonden</h1>
            <p>Helaas kan deze pagina niet worden gevonden.<br />Was u naar iets specifieks op zoek? Neem hieronder contact op.</p>
        </div>
    );
}