import React from "react";
import {Form} from "../../Containers/Form/Form";
import {Field} from "../../Containers/Field/Field";
import "./QuotationForm.scss";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import {PhoneInput} from "../PhoneInput";
import ReactGA from 'react-ga';
import { wp } from "../../Core/Wordpress/Wordpress";

interface IProps {

}
interface IState {
    requestType?: string,
    isLoading: boolean,
    done: boolean,
}

export class QuotationForm extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: false,
            done: false
        };

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    get submitButton() {
        if (this.state.done) {
            return <input type="submit" className="button primary hollow" disabled />
        }
        return <input type="submit" className="button primary hollow" />;
    }

    get types() {
        return {
            mortgage: {
                name: 'Onroerend goed',
                options: [
                    'Levering in combinatie met hypotheek (aankoop woning)',
                    'Levering, hypotheek in combinatie met samenlevingscontract',
                    'Levering, hypotheek, samenlevingscontract in combinatie met 2 gelijke testamenten',
                    'Levering (zonder hypotheek)',
                    'Oversluiten hypotheek (inclusief 1 doorhaling)',
                    'Losse (tweede) hypotheek',
                    'Verdeling woning (na scheiding)',
                    'Royementsakte (doorhalen hypotheek)',
                ]
            },
            family: {
                name: 'Familierecht',
                options: [
                    'Samenlevingscontract',
                    'Samenlevingscontract in combinatie met 2 gelijke testamenten',
                    '1 testament (maatwerk prijs)',
                    '2 gelijke testamenten',
                    'Levenstestament / notariële volmacht',
                    '2 gelijke levenstestamenten / notariële volmachten',
                    'Voogdijregeling',
                    'Huwelijkse voorwaarden / partnerschapsvoorwaarden',
                    'Verklaring van erfrecht',
                    'Afwikkeling nalatenschap',
                    'Schenking',
                    'Verdeling woning (na scheiding)',
                ]
            },
            business: {
                name: 'Onderneming',
                options: [
                    'Oprichting besloten vennootschap (B.V.)',
                    'Aandelenoverdracht',
                    'Statutenwijziging B.V.',
                    'Oprichting vereniging / stichting, Statutenwijziging stichting / vereniging',
                    'Aankoop bedrijfspand',
                    'Hypotheek bedrijfspand',
                ]
            },
        } as any;
    }

    get requestTypeInputs() {
        if (!this.state.requestType) {
            return <></>;
        }
        const inputs = this.types[this.state.requestType];
        if (!inputs) {
            return <></>;
        }
        return <ul>
            {inputs.options.map((option: string, index: number) => {
                return <li key={index}>
                    <Field name={'subjects[]'} title={option} inputType={'checkbox'} value={option} />
                </li>
            })}
        </ul>
    }

    handleFormSubmit(data: any) {
        this.setState({isLoading: true});
        data.form = 'Offerte';
        wp().submissions.post(data).then((answer: any) => {
            console.log({answer});

            ReactGA.event({
                category: 'Quotation',
                action: 'Submitted quotation form'
            });

            this.setState({isLoading: false, done: true});
        }).catch((reason: any) => {
            this.setState({isLoading: false});
            try {
                reason = JSON.parse(reason.message);

                if (reason.ip) {
                    alert('U kunt maar 1 formulier per 30 minuten verzenden');
                }
            } catch (e) {
                console.error({reason});
            }
        })
    }
    handleFormFocus() {
        ReactGA.event({
            category: 'Quotation',
            action: 'Touched quotation form'
        });
    }
    handleFormChange(data: any, dirty: boolean = true) {
        if (dirty && data.type) {
            const  requestType= data.type;

            this.setState({requestType});
        }
    }

    render() {
        return (
            <React.Fragment>
                { this.state.isLoading ? <LoadingIndicator /> : null }
                <div className="quotation-form-wrapper" id={"quotation-form"}>
                    <div className={"quotation-form-container " + (this.state.done ? 'done' : '')}>
                        <div className="form-wrapper" onClick={this.handleFormFocus} onTouchStart={this.handleFormFocus}>
                            <p>Laat uw gegevens bij ons achter, en wij nemen zo snel mogelijk contact met u op.</p>
                            <Form onSubmit={this.handleFormSubmit} onChange={this.handleFormChange} name={"quotation"} >
                                <Field name="name" title="Naam" inputType="text" required={true} />
                                <PhoneInput name="phone" title="Telefoonnummer" required={true} />
                                <Field name="email" title="Email" inputType="text" required={true} />
                                <Field name="city" title="Woonplaats" inputType="text" />

                                <div className="question-wrapper">
                                    <span>Waar gaat uw vraag over?</span>
                                    <div className="question">
                                        {Object.keys(this.types).map((key: string, index: number) => {
                                            const type = this.types[key];
                                            return <Field key={index} name="type" value={key} title={type.name} inputType="radio" />;
                                        })}
                                    </div>
                                </div>

                                {this.requestTypeInputs}

                                <Field name="message" title="Vraag of opmerking" inputType="textarea" />
                                <br />
                                <p className="small-text"><em>Door op verzenden te klikken gaat u automatisch akkoord met onze voorwaarden.</em></p>
                                {this.submitButton}
                            </Form>
                        </div>
                        <div className="form-done-wrapper">
                            <p>Bedankt voor uw bericht!</p>
                            <p>Wij nemen z.s.m. contact met u op.</p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
