import React from "react";
import "./ContactDetails.scss";

interface IProps {

}
interface IState {

}

export class ContactDetails extends React.Component<IProps, IState>{
    render() {
        return (
            <div className="contact-details-wrapper">
                <h1 className="alt-font"><span className="hide-for-medium-only">Contactg</span><span className="show-for-medium-only">G</span>egevens</h1>
                <p>T: 0320 248 558<br />E: <a href={'mailto:info@mnnnotariaat.nl'}>info@mnnnotariaat.nl</a></p>
                <p>Middendreef 277<br />
                8233 GT Lelystad<br />
                    <a rel="noopener noreferrer" href="https://www.google.com/maps/dir//Middendreef+277,+8233+GT+Lelystad/@52.4964047,5.472102,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47c62686aa709895:0xb18c2d28a9b03ab7!2m2!1d5.4742907!2d52.4964015!3e0" target="_blank">Route</a></p>
                <table>
                    <tbody>
                    <tr>
                        <td>Maandag</td>
                        <td>08:30 - 12:30 13:30 - 17:00</td>
                    </tr>
                    <tr>
                        <td>Dinsdag</td>
                        <td>08:30 - 12:30 13:30 - 17:00</td>
                    </tr>
                    <tr>
                        <td>Woensdag</td>
                        <td>08:30 - 12:30 13:30 - 17:00</td>
                    </tr>
                    <tr>
                        <td>Donderdag</td>
                        <td>08:30 - 12:30 13:30 - 17:00</td>
                    </tr>
                    <tr>
                        <td>Vrijdag</td>
                        <td>08:30 - 12:30 13:30 - 17:00</td>
                    </tr>
                    <tr>
                        <td>Zaterdag</td>
                        <td>Gesloten</td>
                    </tr>
                    <tr>
                        <td>Zondag</td>
                        <td>Gesloten</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
