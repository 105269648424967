import React from "react";
import {Menu} from "../Menu/Menu";
import "./FooterMenu.scss";

export class FooterMenu extends React.Component{
    render() {
        return (
            <div className="footer-menu-wrapper">
                <Menu menuSlug={'bottom-menu'} />
            </div>
        );
    }
}