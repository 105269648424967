import React from "react";
import { ContactBar } from "../ContactBar/ContactBar";
import { FooterMenu } from "../FooterMenu/FooterMenu";
import { CookieBar } from "../CookieBar/CookieBar";
import {Copyright} from "../Copyright/Copyright";

export class Footer extends React.Component {
    render() {
        return (
            <div className="footer-wrapper">
                <ContactBar />
                <FooterMenu />
                <CookieBar />
                <Copyright />
            </div>
        );
    }
}