import React from "react";
import './FrontContent.scss';

type IProps = {
    align: string,
    children: any,
    image?: any
};
type IState = {
};

export class FrontContent extends React.Component<IProps, IState>{
    protected id: string = btoa((Math.floor(Math.random() * 20000) + 1).toString()).split('=').join('');

    constructor(props: IProps) {
        super(props);

        this.state = {

        }
    }

    get className() {
        const classes = ['content-wrapper'];

        classes.push('align-' + this.props.align);

        return classes.join(' ');
    }
    get contents() {
        if (this.props.align === 'left') {
            return (
                <React.Fragment>
                    <div className="content-left text-container">
                        {this.props.children}
                    </div>
                    <div className="content-right image-container">
                        {this.props.image}
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="content-left image-container">
                        {this.props.image}
                    </div>
                    <div className="content-right text-container">
                        {this.props.children}
                    </div>
                </React.Fragment>
            );
        }
    }

    render() {
        return (
            <div className={this.className} id={this.id}>
                {this.contents}
            </div>
        );
    }
}