import React from "react";

export class ContactButton extends React.Component{
    get style() {
        return {
            transform: 'translateY(50%)'
        };
    }
    goToContact() {
        const form = document.getElementById('contact-form');
        const page = document.getElementById('page');
        if (form && page) {
            const top = form.offsetTop;
            window.scrollTo({
                left: 0,
                top: top,
                behavior: "smooth"
            });
            setTimeout(() => {
                const firstInput = document.querySelectorAll('input[name="name"]');
                if (firstInput[0]) {
                    (firstInput[0] as HTMLInputElement).focus();
                }
            }, 500);
        }
    }
    render() {
        return <button style={this.style} className="button primary hollow" onClick={this.goToContact}>Contact opnemen</button>
    }
}