import React from "react";
import "./Copyright.scss";

export class Copyright extends React.Component{
    render() {
        return (
            <div className="footer-copyright">
                Theme by <a href="http://squde.nl" target="_blank" rel="noopener noreferrer">Squde</a>
            </div>
        );
    }
}