import * as React from "react";
import {Field} from "../Containers/Field/Field";
import {ReactText} from "react";

interface IProps {
    value?: any;
    name: string;
    title: string;
    placeholder?: string;
    required?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    onChange?: (e: any) => void;
}

interface IState {
    newValue: any;
    validationErrors?: string;
}

export class PhoneInput extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.validator = this.validator.bind(this);
    }

    validator(value: ReactText): string {
        let error = '';

        if (value.toString().length > 0 && !this.isPhoneNumber(value.toString())) {
            error = 'Dit telefoonnummer is onjuist';
        }


        return error;
    }

    isPhoneNumber(value: string) {
        value = value.split(' ').join('');

        //eslint-disable-next-line
        const match = value.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im);

        if (value.split('')[0] === '+' && match) {
            return value.length === '+31611111111'.length;
        }

        return match;
    }

    render() {
        return (
            <Field
                inputType="text"
                validator={this.validator}
                value={this.props.value}
                name={this.props.name}
                title={this.props.title}
                placeholder={this.props.placeholder}
                required={this.props.required}
                readonly={this.props.readonly}
                disabled={this.props.disabled}
                onChange={this.props.onChange}
                autoComplete={false}
            />
        );
    }
}