// @flow
import * as React from 'react';
import { Route, BrowserRouter as ReactRouter, Switch } from 'react-router-dom'
import { FrontPage } from "../../Pages/FrontPage/FrontPage";
import Topbar from "../../Components/Topbar/Topbar";
import { Page } from "../../Pages/Page/Page";
import { Footer } from "../../Components/Footer/Footer";
import { Helmet, HelmetProvider } from 'react-helmet-async';


type Props = {};
type State = {};

export class Router extends React.Component<Props, State> {
    render() {
        return (
            <HelmetProvider>
                <ReactRouter>
                    <Helmet>
                        <title>MNN Notariaat</title>
                        <meta name="description" content="Midden Nederland Notariaat - Uw Notaris in Lelystad" />
                    </Helmet>
                    <div>
                        <Topbar/>
                        <Switch>
                            <Route exact path="/" component={FrontPage}/>
                            <Route path="/:slug" component={Page}/>
                        </Switch>
                        <Footer/>
                    </div>
                </ReactRouter>
            </HelmetProvider>
        );
    };
};