import * as React from 'react';
import { IPage } from "../../Interfaces/IPage";
import { ButtonBar } from "../../Components/ButtonBar/ButtonBar";
import { FrontContent } from "../../Components/FrontContent/FrontContent";
import {MapsBar} from "../../Components/MapsBar/MapsBar";
import ScrollToTopOnMount from "../../Containers/ScrollToTopOnMount/ScrollToTopOnMount";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './FrontPage.scss';
import { LoadingIndicator } from "../../Components/LoadingIndicator/LoadingIndicator";
import ReactGA from 'react-ga';
import {ParallaxImage} from "../../Components/ParallaxImage/ParallaxImage";
import { wp } from "../../Core/Wordpress/Wordpress";


type IProps = {
    
};
type IState = {
    isLoading: boolean,
    page?: IPage,
    content?: any
};

export class FrontPage extends React.Component<IProps, IState> {
    protected splitContentOn: string = '<hr class="wp-block-separator"/>';

    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true,
        }
    }

    get contents() {
        if (this.state.isLoading) {
            return <LoadingIndicator />;
        }
        return this.state.content.split(this.splitContentOn).map((content: string, index: number) => {
            const align = index %2 === 0 ? 'left' : 'right';
            const contentDiv = <div dangerouslySetInnerHTML={this.parseHtmlContent(content)} />;
            let image = undefined;
            if (this.state.page && this.state.page.season_pictures) {
                const pictures = this.state.page.season_pictures[index];
                if (pictures[this.currentSeason]) {
                    image = pictures[this.currentSeason].full;
                }
            }
            return (
                <React.Fragment key={index}>
                    <FrontContent align={align} image={(<ParallaxImage src={image} scale={1.5} extraOffset={-50 * index} />)}>
                        {contentDiv}
                    </FrontContent>
                    {index === 0 ? (<ButtonBar />) : null}
                </React.Fragment>
            );
        })
    }

    get currentSeason() {
        const month = (new Date()).getMonth() + 1;
        let season = '';
        switch(month) {
            case 9:
            case 10:
            case 11:
            case 12:
            case 1:
            case 2:
                season = 'winter';
            break;
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
                season = 'summer';
            break;
        }
        return season;
    }

    parseHtmlContent(content: string = ''): {__html: string} {
        return {
            __html: content
        }
    }
    componentDidMount(): void {
        wp().frontpage.get().then((page: IPage) => {
            ReactGA.pageview('/');
            this.setState({
                isLoading: false,
                page: page,
                content: page.content
            })
        });
    }

    render() {
        return (
            <HelmetProvider>
                <Helmet>
                    {this.state.page && this.state.page.seo && this.state.page.seo.title ? (<title>{this.state.page.seo.title}</title>) : null}
                    {this.state.page && this.state.page.seo && this.state.page.seo.description ? (
                        <meta name="description" content={this.state.page.seo.description} />
                    ) : null}
                </Helmet>
                <div className="front-page">
                    <ScrollToTopOnMount/>
                    {this.contents}
                    <MapsBar />
                </div>
            </HelmetProvider>
        );
    };
}