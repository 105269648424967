import React from "react";
import './MapsBar.scss';

export class MapsBar extends React.Component {
    render() {
        return (
            <div className="mapouter">
                <div className="gmap_canvas">
                    <iframe title="Maps" id="gmap_canvas"
                            src="https://maps.google.com/maps?q=middendreef%20277%20lelystad&t=&z=15&ie=UTF8&iwloc=&output=embed"
                            frameBorder="0" scrolling="no"/>
                </div>
                <div className="gmap-overlay" />
            </div>
        );
    }
}