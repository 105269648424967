import "./Content.scss";
import React, { ReactElement, ReactNode } from "react";
import parse, { domToReact } from 'html-react-parser';
import { QuotationForm } from "../QuotationForm/QuotationForm";

interface IProps {
    children: string
}

export function Content(props: IProps) {
    let content = props.children;

    content = !content ? '' : content;

    /* Remove empty paragraphs */
    content = (() => {
        return content.split('<p></p>').join('');
    })();


    const options = {
        replace: (props: any) => {
            const {attribs, children, name} = props;

            if (!attribs) return;

            switch (name) {
                case 'quotationform':
                    return React.createElement(QuotationForm, attribs);
            }
        }
    };



    return (
        <div className={"cms-content-wrapper"}>{parse(content, options )}</div>
    )
}