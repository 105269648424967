import React from "react";
import './ButtonBar.scss';
import {NavLink} from "react-router-dom";
import personIcon from '../../resources/icons/person.svg'
import briefcaseIcon from '../../resources/icons/briefcase.svg'
import homeIcon from '../../resources/icons/home.svg'

interface IProps {

}
interface IState {

}
interface innerButtonData {
    icon: any
    title: string
    excerpt: string
    url: string
}

export class ButtonBar extends React.Component<IProps, IState>{
    get contents() {
        return ([{
            icon: personIcon,
            title: 'Privé',
            excerpt: 'Wij beantwoorden uw vragen over o.a. testamenten, schenkingen en het huwelijk.',
            url: '/familierecht'
        },{
            icon: homeIcon,
            title: 'Woning',
            excerpt: 'Heeft u een vraag over o.a. uw (nieuwe) hypotheek, een lening of koopovereenkomst?',
            url: '/woning'
        },{
            icon: briefcaseIcon,
            title: 'Zakelijk',
            excerpt: 'Wij helpen u op weg met o.a. contracten, investeringen en aandelen.',
            url: '/onderneming'
        },] as innerButtonData[]).map((buttonData: innerButtonData, index: number) => {
            return <div className="button-wrapper" key={index}>
                <div className="button-inner">
                    <img src={buttonData.icon} alt="Privé"/>
                    <h3 className="alt-font">{buttonData.title}</h3>
                    <p>{buttonData.excerpt}</p>
                    <NavLink to={buttonData.url} className="button white hollow">Meer informatie</NavLink>
                </div>
            </div>
        });
    }

    render() {
        return (
            <div className="button-bar-wrapper">
                {this.contents}
            </div>
        );
    }
}