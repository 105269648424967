import React from "react";
import './CookieBar.scss';

interface IProps {

}
interface IState {
    shouldShow: boolean
}

export class CookieBar extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);

        this.state = {
            shouldShow: false,
        };

        this.agreed = this.agreed.bind(this);
    }

    get classNames(): string {
        const classes = ['cookie-bar-wrapper'];

        if (this.state.shouldShow) {
            classes.push('show');
        }

        return classes.join(' ');
    }

    componentDidMount(): void {
        const agreed = this.getCookie('agreed');
        if (agreed < 1) {
            this.setState({shouldShow: true});
        }
    }

    getCookie(name: string): number {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");
        if (parts.length === 2) {
            const newParts = parts.pop();
            if (newParts) {
                const returnValue = newParts.split(";").shift();
                if (returnValue) {
                    return parseInt(returnValue);
                }
            }
        }
        return 0;
    }

    setCookie(name: string, value: string|number, days: number) {
        const d = new Date();
        d.setTime(d.getTime() + 24*60*60*1000*days);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toUTCString();
    }

    agreed() {
        this.setCookie('agreed', 1, 365);
        this.setState({shouldShow: false});
    }

    render() {
        return (
            <div className={this.classNames}>
                <div className="cookie-bar">
                    <div className="cookie-bar-contents">
                        <p>Deze site maakt gebruik van analytische cookies om uw gebruikerservaring te optimaliseren. U gaat, door gebruik van deze site, akkoord met ons cookiebeleid.</p>
                    </div>
                    <div className="close-button" onClick={this.agreed} />
                </div>
            </div>
        );
    }
}