import React from 'react';
import './Topbar.scss';
import Topmenu from "../Topmenu/Topmenu";
import logo from '../../resources/logo.svg';
import { NavLink } from "react-router-dom";

interface IProps {}
interface IState {
    height: number,
    marginTop: number,
    showMenu: boolean
}

class Topbar extends React.Component<IProps, IState> {
    desiredHeight = 100;
    startingPosition = 0;

    constructor(props: IProps = {}) {
        super(props);

        this.state = {
            height: this.desiredHeight,
            marginTop: 0,
            showMenu: false
        };
        // this.handleResize = this.handleResize.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }
    get height() {
        return this.state.height;
    }
    get marginTop() {
        return this.state.marginTop;
    }

    get menu() {
        const classes = ['menu-wrapper'];
        if (this.state.showMenu) {
            classes.push('open');
        }
        const className = classes.join(' ');
        return (
            <React.Fragment>
                <div className={className} onClick={this.toggleMenu} >
                    <Topmenu />
                </div>
                <div className="bg" onClick={this.toggleMenu} />
            </React.Fragment>
        );
    }

    get menuButton() {
        const classes = ['living-menu-button'];

        if (this.state.showMenu) {
            classes.push('menu-open');
        }

        return <div className={classes.join(' ')} onClick={this.toggleMenu}>
            <div className="line" />
            <div className="line" />
            <div className="line" />
        </div>;
    }

    componentDidMount(): void {
        setTimeout(() => {
            window.addEventListener('scroll', this.handleScroll);
        }, 50);
    }
    componentWillUnmount(): void {
        window.removeEventListener('scroll', this.handleScroll);
    }
    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>, nextContext: any): boolean {
        return JSON.stringify(nextProps) !== JSON.stringify(this.props) ||
            JSON.stringify(nextState) !== JSON.stringify(this.state);
    }

    handleScroll(event: Event) {
        const scrollTop = document.getElementsByTagName('html')[0].scrollTop as number;
        const direction = scrollTop < this.startingPosition ? 'up' : 'down';

        if (this.state.showMenu || (scrollTop < (this.height * 2) || (direction === 'up'))) {
            this.setState({marginTop: 0});
        } else if (direction === 'down') {
            this.setState({marginTop: this.height * -1});
        }


        setTimeout(() => {
            this.startingPosition = scrollTop;
        }, 1000);
    }

    toggleMenu() {
        this.setState({showMenu: !this.state.showMenu});
    }

    // handleResize(event: Event) {
    //     const w = window.outerWidth;
    //     if (w < 1000) {
    //         this.setState({height: 100});
    //     } else {
    //         this.setState({height: 50});
    //     }
    // }

    render() {
        return (
            <React.Fragment>
                <div className="top-bar-wrapper" style={{height: this.height}}>
                    <div className="top-bar" style={{gridTemplateRows: this.height, marginTop: this.marginTop}}>
                        <div className="top-bar-contents">
                            <div className="header-contact-details-wrapper">
                                <div className="contact-details-inner">
                                    <div>
                                        <b>T: </b> 0320 - 248 558
                                    </div>
                                    <div>
                                        <b>E: </b> <a href={'mailto:info@mnnnotariaat.nl'}>info@mnnnotariaat.nl</a>
                                    </div>
                                </div>
                            </div>
                            <NavLink to={'/'} className="logo">
                                <img src={logo} alt="Logo"/>
                            </NavLink>
                            <div className="slogan alt-font">
                                <span className="show-for-large">
                                    eenvoud in <span className="primary">notariaat</span>
                                </span>
                            </div>
                            <div className={"quotation-wrapper"}>
                                <NavLink to={'/offerte/'} className="button primary">
                                    Offerte aanvragen
                                </NavLink>
                            </div>
                            <div className="menu-button-wrapper">
                                {this.menuButton}
                            </div>
                        </div>
                    </div>
                </div>
                {this.menu}
            </React.Fragment>
        );
    }
}

export default Topbar;
