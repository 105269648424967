import React from 'react';
import './Topmenu.scss';
import {Menu} from "../Menu/Menu";

class Topmenu extends React.Component {
    render() {
        return (
            <div className="top-menu alt-font">
                <Menu menuSlug={'top-menu'} />
            </div>
        );
    }
}

export default Topmenu;