import React from "react";
import { ContactForm } from "../ContactForm/ContactForm";
import { ContactDetails } from "../ContactDetails/ContactDetails";
import './ContactBar.scss';

interface IProps {

}
interface IState {

}

export class ContactBar extends React.Component<IProps, IState>{
    render() {
        return (
            <div className="contact-bar-wrapper">
                <div className="contact-bar">
                    <ContactForm />
                    <ContactDetails />
                </div>
            </div>
        );
    }
}