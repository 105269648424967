import React from "react";
import './ParallaxImage.scss';
import {LoadingIndicator} from "../LoadingIndicator/LoadingIndicator";
import {IMedia} from "../../Interfaces/IMedia";
import { wp } from "../../Core/Wordpress/Wordpress";

interface IProps {
    src?: string
    id?: number|null,
    scale?: number,
    extraOffset?: number
}
interface IState {
    translateY: number,
    isLoading: boolean,
    src?: string
}

export class ParallaxImage extends React.Component<IProps, IState> {
    protected identifier: string = '';

    constructor(props: IProps) {
        super(props);

        this.state = {
            translateY: 0,
            isLoading: !!props.id
        };

        this.handleScroll = this.handleScroll.bind(this);
    }

    get myId() {
        if (this.identifier.length > 0) {
            return this.identifier;
        }
        let result           = '';
        const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for ( let i = 0; i < 10; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        this.identifier = result;
        return this.identifier;
    }

    get src() {
        if (this.props.src) {
            return this.props.src;
        }
        if (this.state.src) {
            return this.state.src;
        }
        return null;
    }

    get scale() {
        if (this.props.scale) {
            return this.props.scale;
        }
        return 1;
    }

    componentDidMount(): void {
        document.addEventListener('scroll', this.handleScroll);
        this.handleScroll();
        this.loadMedia();
    }
    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.loadMedia();
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        let scrollTop = document.getElementsByTagName('html')[0].scrollTop as number;
        const elm = document.getElementById(this.myId);
        if (elm) {
            scrollTop = scrollTop - elm.offsetTop - (elm.offsetHeight / 2);
        }

        const extra = this.props.extraOffset ? this.props.extraOffset : 0;

        this.setState({translateY: (scrollTop / 8) + extra});
    }

    loadMedia() {
        if (this.props.id && this.props.id > 0) {
            wp().image.get(this.props.id).then((image: IMedia) => {
                this.setState({
                    isLoading: false,
                    src: image.media_details.sizes.full.source_url
                })
            });
        } else {
            this.setState({
                isLoading: false,
                src: 'https://images.unsplash.com/photo-1487147264018-f937fba0c817?ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80'
            })
        }
    }

    render() {
        return this.src ?
            <div id={this.myId} className="parallax-image"
                 style={{backgroundImage: 'url(' + this.src + ')', transform: 'translateY(' + this.state.translateY + 'px) scale(' + this.scale + ')'}}/>
                 :
            <LoadingIndicator contained={true} />;
    }
}