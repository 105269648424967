import React from "react";
import {Form} from "../../Containers/Form/Form";
import {Field} from "../../Containers/Field/Field";
import "./ContactForm.scss";
import { LoadingIndicator } from "../LoadingIndicator/LoadingIndicator";
import {PhoneInput} from "../PhoneInput";
import ReactGA from 'react-ga';
import { wp } from "../../Core/Wordpress/Wordpress";

interface IProps {

}
interface IState {
    phoneRequired: boolean,
    emailRequired: boolean,
    isLoading: boolean,
    done: boolean,
}

export class ContactForm extends React.Component<IProps, IState>{
    constructor(props: IProps) {
        super(props);

        this.state = {
            phoneRequired: false,
            emailRequired: false,
            isLoading: false,
            done: false
        };

        this.handleFormChange = this.handleFormChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    get submitButton() {
        if (this.state.done) {
            return <input type="submit" className="button white hollow" disabled value={'Verzenden'} />
        }
        return <input type="submit" className="button white hollow" value={'Verzenden'} />;
    }

    handleFormSubmit(data: any) {
        this.setState({isLoading: true});
        data.form = 'Contactform';
        wp().submissions.post(data).then((answer: any) => {
            console.log({answer});

            ReactGA.event({
                category: 'Contact',
                action: 'Submitted contact form'
            });

            this.setState({isLoading: false, done: true});
        }).catch((reason: any) => {
            this.setState({isLoading: false});
            try {
                reason = JSON.parse(reason.message);

                if (reason.ip) {
                    alert('U kunt maar 1 formulier per 30 minuten verzenden');
                }
            } catch (e) {
                console.error({reason});
            }
        })
    }
    handleFormChange(data: any, dirty: boolean = true) {
        if (dirty) {
            const phoneRequired = data.way_of_contact === 'call';
            const emailRequired = data.way_of_contact === 'mail';

            this.setState({phoneRequired, emailRequired});
        }
    }
    handleFormFocus() {
        ReactGA.event({
            category: 'Contact',
            action: 'Touched contact form'
        });
    }

    render() {
        return (
            <React.Fragment>
                { this.state.isLoading ? <LoadingIndicator /> : null }
                <div className="contact-form-wrapper" id={"contact-form"}>
                    <h1 className="alt-font">Contact</h1>
                    <div className={"contact-form-container " + (this.state.done ? 'done' : '')}>
                        <div className="form-wrapper" onClick={this.handleFormFocus} onTouchStart={this.handleFormFocus}>
                            <p>Laat uw gegevens bij ons achter, en wij nemen zo snel mogelijk contact met u op.<br />Precies op de wijze zoals u wenst.</p>
                            <Form onSubmit={this.handleFormSubmit} onChange={this.handleFormChange} name={"contact"} >
                                <Field name="name" title="Naam" inputType="text" required={true} />
                                <PhoneInput name="phone" title="Telefoonnummer" required={this.state.phoneRequired} />
                                <Field name="email" title="Email" inputType="text" required={this.state.emailRequired} />
                                <div className="question-wrapper">
                                    <span>Ik wil graag</span>
                                    <div className="question">
                                        <Field name="way_of_contact" value="call" title="gebeld" inputType="radio" checked={true} />
                                        <Field name="way_of_contact" value="mail" title="gemaild" inputType="radio" />
                                    </div>
                                    <span>worden</span>
                                </div>
                                <Field name="message" title="Vraag of opmerking" inputType="textarea" />
                                <br />
                                <p className="small-text"><em>Door op verzenden te klikken gaat u automatisch akkoord met onze voorwaarden.</em></p>
                                {this.submitButton}
                            </Form>
                        </div>
                        <div className="form-done-wrapper">
                            <p>Bedankt voor uw bericht!</p>
                            <p>Wij nemen z.s.m. contact met u op.</p>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
