import React from "react";
import {IPage} from "../../Interfaces/IPage";
import { getBasePath } from "../../Helpers/Routing";
import {LoadingIndicator} from "../../Components/LoadingIndicator/LoadingIndicator";
import {ParallaxImage} from "../../Components/ParallaxImage/ParallaxImage";
import {ContactButton} from "../../Components/ContactButton/ContactButton";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import "./Page.scss";
import {NotFound} from "../NotFound/NotFound";
import ReactGA from 'react-ga';
import { Content } from "../../Components/Content/Content";
import { wp } from "../../Core/Wordpress/Wordpress";

interface IProps {
    match: {params: {slug: string}},
    history: string[]
}
interface IState {
    isLoading: boolean,
    page?: IPage
}

export class Page extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            isLoading: true
        };
    }

    get slug() {
        return this.props.match.params.slug;
    }

    get html() {
        if (!this.state.page) {
            return null;
        }

        return <Content>{this.state.page.content}</Content>;
    }

    componentDidMount(): void {
        this.loadPage();
    }
    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.match.params.slug !== this.props.match.params.slug) {
            this.loadPage()
        }
    }

    fixLinks() {
        document.querySelectorAll('.page-wrapper a[href]:not([href="#"])').forEach((a: Element) => {
            a.addEventListener('click', (e) => {
                e.preventDefault();
                const url = getBasePath((a as HTMLAnchorElement).href);
                this.props.history.push(url);
            });
        });
    }

    loadPage() {
        this.setState({isLoading: true, page: undefined});
        wp().page.get({slug: this.slug}).then((page: IPage) => {
            ReactGA.pageview('/' + this.slug);
            this.setState({
                isLoading: false,
                page: page
            });
            this.fixLinks();
            window.scroll(0,0);
        });
    }

    render() {
        return this.state.isLoading ?
            <LoadingIndicator />
            :
                this.state.page ?
                    (
                        <HelmetProvider>
                            <Helmet>
                                {this.state.page && this.state.page.seo && this.state.page.seo.title ? (<title>{this.state.page.seo.title}</title>) : null}
                                {this.state.page && this.state.page.seo && this.state.page.seo.description ? (
                                    <meta name="description" content={this.state.page.seo.description} />
                                ) : null}
                            </Helmet>
                            <div className="page-wrapper" id="page">
                                <div className="header-image-wrapper">
                                    <ParallaxImage id={this.state.page.featured_media} scale={1.2} />
                                </div>
                                <div className="page-contents-wrapper">
                                    <div className="page-contents">
                                        <div className="left-side">
                                            <h1 className="alt-font">{this.state.page.title}</h1>
                                            {this.html}
                                        </div>
                                        <div className="right-side">
                                            <ContactButton />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </HelmetProvider>
                    )
                    :
                    <NotFound/>
        ;
    }
}