/* eslint no-eval: 0 */
import config from '../../config.json';
import axios from 'axios';
import { IPost } from "../../Interfaces/IPost";
import { IPage } from "../../Interfaces/IPage";
import { IMenu } from "../../Interfaces/IMenu";
import {IMedia} from "../../Interfaces/IMedia";
import Wordpress from "squde-wp-api";


class CustomWordpress extends Wordpress {
    // get(target: any, name: string) {
    //     let subObject;
    //     if (typeof eval('this[\'' + name + '\']') === 'function') {
    //         subObject = eval('this.' + name)();
    //     } else {
    //         subObject = this.custom(name);
    //     }
    //
    //     return subObject;
    // }

    // fetch(path: string) {
    //     const thisObject = this;
    //     return axios
    //         .get(config.endpoint + path)
    //         .then((response: any) => {
    //             if (response.status === 200) {
    //                 return thisObject.fixData(response.data);
    //             } else {
    //                 throw new Error('Empty data')
    //             }
    //         })
    //         .catch((error: any) => {
    //             if (error.response.data) {
    //                 console.log({error});
    //                 throw new Error(error.response.data);
    //             }
    //             console.warn(error);
    //             return [];
    //         });
    // }

    // post(path: string, data: any) {
    //     const thisObject = this;
    //     return axios
    //         .post(config.endpoint + path, data)
    //         .then((response: any) => {
    //             if (response.status === 200) {
    //                 if (response.data && typeof response.data === 'object') {
    //                     return thisObject.fixData(response.data);
    //                 } else {
    //                     return response.data;
    //                 }
    //             } else {
    //                 throw new Error('Empty data')
    //             }
    //         })
    //         .catch((error: any) => {
    //             if (error.response && error.response.data) {
    //                 throw new Error(JSON.stringify(error.response.data));
    //             }
    //             console.warn(error);
    //             return [];
    //         });
    // }
    //
    // posts() {
    //     const api = new WordpressApi();
    //     return {
    //         get: (params: { [s: string]: string; } = {}): Promise<IPost[]> => {
    //             return api.fetch('posts/?' + api.buildQuery(params));
    //         }
    //     }
    // }
    //
    // pages() {
    //     const api = new WordpressApi();
    //     return {
    //         get: (params: { [s: string]: string; } = {}): Promise<IPage[]> => {
    //             return api.fetch('pages/?' + api.buildQuery(params));
    //         }
    //     }
    // }
    //
    // page() {
    //     const api = new WordpressApi();
    //     return {
    //         get: (params: { [s: string]: string; } = {}): Promise<IPage|null> => {
    //             return api.pages().get(params).then((pages: IPage[]) => {
    //                 if (pages[0]) {
    //                     return pages[0];
    //                 }
    //                 return null;
    //             })
    //         }
    //     }
    // }
    //
    // frontpage() {
    //     const api = new WordpressApi();
    //     return {
    //         get: (): Promise<IPage> => {
    //             return api.fetch('frontpage');
    //         }
    //     }
    // }
    //
    // menus() {
    //     const api = new WordpressApi();
    //     return {
    //         get: (params: { [s: string]: string; } = {}): Promise<IMenu[]> => {
    //             return api.fetch('menus/?' + api.buildQuery(params));
    //         }
    //     }
    // }
    //
    // submissions() {
    //     const api = new WordpressApi();
    //     return {
    //         post: (params: { [s: string]: string; } = {}): Promise<any> => {
    //             return api.post('submissions/', api.buildQuery(params));
    //         }
    //     }
    // }
    //
    //
    // media() {
    //     const api = new WordpressApi();
    //     return {
    //         get: (params: { [s: string]: string; } = {}): Promise<IMedia[]> => {
    //             return api.fetch('media/?' + api.buildQuery(params));
    //         }
    //     }
    // }
    //
    // image() {
    //     const api = new WordpressApi();
    //     return {
    //         get: (id: string|number): Promise<IMedia> => {
    //             return api.fetch('media/' + id);
    //         }
    //     }
    // }

    // custom(name: string) {
    //     return {
    //         get: (params: { [s: string]: string; } = {}) => {
    //             return this.fetch(name + '/?' + this.buildQuery(params));
    //         }
    //     }
    // }

    // fixData(data: any) {
    //     const isArray = !!data[0];
    //     if (!isArray) {
    //         data = [data];
    //     }
    //     data = data.map((row: any) => {
    //         if (row.content && row.content.rendered) {
    //             row.content = row.content.rendered;
    //         }
    //         if (row.excerpt && row.excerpt.rendered) {
    //             row.excerpt = row.excerpt.rendered;
    //         }
    //         if (row.guid && row.guid.rendered) {
    //             row.guid = row.guid.rendered;
    //         }
    //         if (row.title && row.title.rendered) {
    //             row.title = row.title.rendered;
    //         }
    //         if (row.date) {
    //             row.date = new Date(row.date);
    //         }
    //         if (row.date_gmt) {
    //             row.date_gmt = new Date(row.date_gmt);
    //         }
    //         if (row.modified) {
    //             row.modified = new Date(row.modified);
    //         }
    //         if (row.modified_gmt) {
    //             row.modified_gmt = new Date(row.modified_gmt);
    //         }
    //         return row;
    //     });
    //     if (!isArray) {
    //         return data[0];
    //     }
    //     return data;
    // }

    // buildQuery(data: string|{ [s: string]: string; }) {
    //     // If the data is already a string, return it as-is
    //     if (typeof (data) === 'string') return data;
    //
    //     // Create a query array to hold the key/value pairs
    //     const query = [];
    //
    //     // Loop through the data object
    //     for (let key in data) {
    //         if (data.hasOwnProperty(key)) {
    //             // @ts-ignore
    //             if (typeof data[key] === 'object' && data[key].join) {
    //                 // @ts-ignore
    //                 data[key] = data[key].join('||');
    //             }
    //
    //             // Encode each key and value, concatenate them into a string, and push them to the array
    //             query.push(encodeURIComponent(key) + '=' + encodeURIComponent(data[key]));
    //         }
    //     }
    //
    //     // Join each item in the array with a `&` and return the resulting string
    //     return query.join('&');
    // }
}

export function wp() {
    const endPoint = window.location.host === 'mnnnotariaat.nl' ? 'https://mnnnotariaat.nl' : 'http://mnnnotariaat.local';
    return new CustomWordpress(endPoint + "/wp-json/wp/v2/");
}
//
// const target = {};
//
// const Wordpress = new Proxy(target, new WordpressApi());
//
// export default Wordpress;